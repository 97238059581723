<template>
    <div class="jw-about-me" id="o-mnie">
        <div class="container">
            <div class="jw-bio">
                <div class="jw-title">
                    {{ title }}
                </div>
                <div class="jw-text" v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "AboutMe",
    computed: {
        title() {
            return this.aboutMeSections ? this.aboutMeSections[0].title : ''
        },
        content() {
            return this.aboutMeSections ? this.aboutMeSections[0].content.html : ''
        }
    },
    apollo: {
        aboutMeSections: gql`
        query {
            aboutMeSections {
                title,
                content {
                    html
                }
            }
        }
        `
    },
};
</script>

<style scoped>
.jw-about-me {
    background-color: #C6B29D;
    padding: 60px 0;
}
.container {
    display: flex;
}
.jw-title {
    width: auto;
    padding: 40px 70px 40px 40px;
    display: inline-block;
    background: #002D55;
    color: #fff;
    line-height: 1.3em;
    border: 1px solid #fff;
}
.jw-text {
    border: 1px solid #fff;
    padding: 40px 40px 40px 40px;
    margin-left: 50px;
    margin-top: -20px;
}

@media (max-width: 767px) {
    .jw-about-me {
        padding: 30px 0;
    }
    .jw-title {
        display: block;
        padding: 16px;
        text-align: center;
    }
    .jw-text {
        padding: 16px;
        margin-top: 20px;
        margin-left: 0;
    }
}
</style>
