<template>
    <div class="map-container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2469.616105874863!2d19.452385315640434!3d51.75834307967717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471a34d6428bb0ef%3A0x2e268e26528befe0!2zV8OzbGN6YcWEc2thIDEyNCwgOTAtNDQzIMWBw7Nkxbo!5e0!3m2!1sen!2spl!4v1616540264709!5m2!1sen!2spl" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</template>

<script>
export default {
    name: "Map"
};
</script>

<style scoped>
.map-container {
    background-color: #002D55;
}
</style>
