<template>
    <div id="app">
        <Header/>
        <Office/>
        <AboutMe/>
        <NotarialActivities/>
        <ContactDetails/>
        <Map/>
        <Footer/>
        <div v-if="scrollToTopVisible" class="scrollToTop" v-on:click="scrollToTop()">
            <div>
                &#8593;
            </div>
        </div>
    </div>
</template>

<script>
import Header from './components/Header.vue';
import Office from './components/Office.vue';
import AboutMe from './components/AboutMe.vue';
import NotarialActivities from './components/NotarialActivities.vue';
import ContactDetails from './components/ContactDetails.vue';
import Map from './components/Map.vue';
import Footer from './components/Footer.vue';

export default {
    name: "app",
    components: { Header, Office, AboutMe, NotarialActivities, ContactDetails, Map, Footer },
    data() {
        return {
            scrollToTopVisible: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                this.scrollToTopVisible = true;
            } else {
                this.scrollToTopVisible = false;
            }
        },
        scrollToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }
};
</script>

<style>
@import './assets/styles/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

html, body, #app {
  font-family: 'Cinzel', serif;
  line-height: 1.4em;
}

.container {
    max-width: 1080px;
    margin: 0 auto;
    /* border: 1px solid red; */
}
.jw-title {
    font-size: 24px;
    color: rgb(0,45,85);
}
.jw-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: lighter;
    color: rgb(0,45,85);
    text-align: justify;
}
.scrollToTop {
    display: flex;
    position: fixed;
    bottom: 30px;
    right: 10px;
    width: 60px;
    height: 60px;
    background: #002D55;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 3px 15px rgba(200,200,200,0.2);
}
@media (max-width: 1080px) {
    .container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
@media (max-width: 600px) {
    .container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
</style>
