<template>
    <div class="jw-header">
        <div class="container">

            <nav class="jw-nav">
                <a class="jw-nav-item" href="#o-kancelarii">O kancelarii</a>
                <a class="jw-nav-item" href="#o-mnie">Notariusz</a>
                <a class="jw-nav-item" href="#czynnosci-notarialne">Czynności notarialne</a>
                <a class="jw-nav-item" href="#kontakt">Kontakt</a>
            </nav>

            <div class="mobile-nav">
                <div class="nav-panel" v-if="showMobileNav">
                    <a class="jw-nav-item" href="#o-kancelarii">O kancelarii</a>
                    <a class="jw-nav-item" href="#o-mnie">Notariusz</a>
                    <a class="jw-nav-item" href="#czynnosci-notarialne">Czynności notarialne</a>
                    <a class="jw-nav-item" href="#kontakt">Kontakt</a>
                </div>
                <div
                    class="hamburger"
                    v-on:click="toggleNav"
                    v-bind:class="{ open : showMobileNav }"
                >
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </div>
            </div>

            <div class="jw-header-content">
                <div class="jw-header-image">
                    <img src="./../assets/logo.png" width="500" alt="">
                </div>
                <div class="jw-header-address">
                    <div>
                        ul. Wólczańska 124 lok. 2
                    </div>
                    <div>
                        90-527 Łódź
                    </div>
                </div>
                <div class="jw-header-phone">
                    <a href="tel:660256512">+48 660 256 512</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            showMobileNav: false
        }
    },
    methods: {
        toggleNav() {
            this.showMobileNav = !this.showMobileNav;
        }
    }
};
</script>

<style scoped>
html {
    scroll-behavior: smooth !important;
}
.jw-header {
    width: 100%;
    height: 100vh;
    position: relative;
    /* background-image: url('./../assets/poznanski-min.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; */
    background: rgb(0,45,85);
}
.jw-header::after {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,45,85);
    background: linear-gradient(165deg, rgba(0,45,85,0.95) 30%, rgba(255,255,255,0.65) 350%);
    z-index: 10;
}
.container {
    position: relative;
    height: 100%;
    z-index: 20;
}
.jw-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}
.jw-nav-item {
    text-decoration: none;
    color: #fff;
}
.jw-header-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}
/* Fade in effect */
.jw-header-content, .jw-nav {
    opacity: 0;
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 1.4s;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}
@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    20% { opacity: 0; }
    30% { opacity: 0.3; }
    40% { opacity: 0.4; }
    50% { opacity: 0.5; }
    60% { opacity: 0.6; }
    70% { opacity: 0.7; }
    80% { opacity: 0.8; }
    90% { opacity: 0.9; }
    100% { opacity: 1; }
}
@keyframes fadeIn {
    0% { opacity: 0; }
    20% { opacity: 0; }
    30% { opacity: 0.3; }
    40% { opacity: 0.4; }
    50% { opacity: 0.5; }
    60% { opacity: 0.6; }
    70% { opacity: 0.7; }
    80% { opacity: 0.8; }
    90% { opacity: 0.9; }
    100% { opacity: 1; }
}
/* Fade in effect END */

.jw-header-image, .jw-header-address, .jw-header-phone {
    width: auto;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
}
.jw-header-address {
    line-height: 1.5em;
}
.jw-header-phone {
    background: #C6B29D;
    padding: 10px 48px;
}
.jw-header-phone a {
    text-decoration: none;
    color: #fff;
}

.mobile-nav {
    display: none;
    width: 100%;
    height: 60px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}
.mobile-nav .nav-panel {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    z-index: 100;
}
.mobile-nav .nav-panel .jw-nav-item {
    display: block;
    line-height: 60px;
    background-color: #fff;
    color: #002D55;
    text-align: center;
    z-index: 100;
    border-bottom: 1px solid #002D55;
}
.mobile-nav .nav-panel .jw-nav-item:hover {
    background-color: #002D55;
    color: #fff;
}
.hamburger {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.line {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 3px;
}

@media (max-width: 767px) {
    .jw-nav {
        display: none;
    }
    .jw-header-content {
        width: 90%;
    }
    .jw-header-image img {
        max-width: 100%;
    }

    .mobile-nav {
        display: block;
    }
}
</style>
