<template>
    <div class="jw-contact" id="kontakt">
        <div class="container">
            <div class="jw-contact-info">
                <div class="jw-contact-info-text jw-text">
                    <div class="contact-title">
                        {{ title }}
                    </div>
                    <div v-html="content"></div>
                </div>
                <div class="contact-form">
                    <div v-if="isSending" class="jw-text sending">
                        Sending...
                    </div>
                    <form role="form" method="post" ref="form" id="form">
                        <input type="text" id="name" name="name" placeholder="Imię i nazwisko" v-model="name" required>
                        <input type="email" id="email" name="email" placeholder="Adres mailowy" v-model="email" required>
                        <input type="tel" id="phone" name="phone" placeholder="Telefon kontaktowy" v-model="phone">
                        <textarea id="message" name="message" placeholder="Wiadomość" v-model="message" style="height:200px" maxlength="6000" required></textarea>
                        <input type="submit" value="Wyślij" v-on:click="onSubmit($event)">
                    </form>
                    <div v-if="submitError" class="submitError jw-text">
                        Wysyłanie wiadomości nie powiodło się. Spróbuj ponownie za chwilę.
                    </div>
                    <div v-if="submitSuccess" class="submitSuccess jw-text">
                        Wiadomość została wysłana pomyślnie.
                    </div>
                    <div v-if="missingFields" class="missingFields jw-text">
                        Wypełnij wszystkie pola formularza
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "ContactDetails",
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            isSending: false,
            submitError: false,
            submitSuccess: false,
            missingFields: false
        }
    },
    computed: {
        title() {
            return this.contactDetailsSections  ? this.contactDetailsSections [0].title : ''
        },
        content() {
            return this.contactDetailsSections  ? this.contactDetailsSections [0].content.html.replaceAll(/\n/g, '<br><br>') : ''
        }
    },
    apollo: {
        contactDetailsSections : gql`
        query {
            contactDetailsSections  {
                title,
                content {
                    html
                }
            }
        }
        `
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.submitError = false;
            this.submitSuccess = false;
            this.missingFields = false;

            if (this.name.length > 0 && this.email.length > 0 && this.phone.length > 0 && this.message.length > 0) {
                this.isSending = true;

                let data = new FormData();
                data.append('name', this.name);
                data.append('email', this.email);
                data.append('phone', this.phone);
                data.append('message', this.message);

                this.$http.post('/handler.php', data).then(() => {
					this.clearForm();
                    this.isSending = false;
                    this.submitError = false;
                    this.submitSuccess = true;
				}).catch((e) => {
                    console.log(e)
                    this.isSending = false;
                    this.submitError = true;
                    this.submitSuccess = false;
				});

            } else {
                this.missingFields = true;
            }
        },
        clearForm() {
            this.name = '';
            this.email = '';
            this.phone = '';
            this.message = '';
            this.isSending = false;
        }
    }
};
</script>

<style scoped>
.jw-contact {
    background: #c6b29d61;
    padding: 60px 0;
}
.jw-contact-info {
    max-width: 560px;
    position: relative;
}
.jw-contact-info-text {
    border: 1px solid #C6B29D;
    padding: 20px 40px 20px 20px;
    background: #fff;
}
.contact-title {
    font-weight: bold;
    margin-bottom: 10px;
}
.contact-form {
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #C6B29D;
    width: 500px;
    padding: 20px;
    box-sizing: border-box;
    max-height: 400px;
}
.contact-form input, .contact-form textarea {
    box-sizing: border-box;
    display: block;
    width: 460px;
    margin-bottom: 10px;
    padding-left: 10px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 0;
    box-shadow: none;
    color: #002D55;
    font-family: 'Open Sans', sans-serif;
}
.contact-form input {
    height: 30px;
}
.contact-form textarea {
    resize: none;
    padding-top: 6px;
    max-height: 140px;
}
.contact-form input[type="submit"] {
    background-color: #fff;
    cursor: pointer;
    color: #002D55;
}
.contact-form input[type="submit"]:hover {
    background-color: #002D55;
    color: #fff;
    border-color: #002D55;
    transition: 0.3s all;
}
.sending {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #C6B29D;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}
:-moz-placeholder { /* Firefox 18- */
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}

input:focus, textarea:focus {
    outline: none !important;
    border-color: #002D55;
}
.submitError, .missingFields {
    color: #c80101;
    font-weight: bold;
    font-size: 12px;
}
.submitSuccess {
    color: #002D55;
    font-weight: bold;
    font-size: 12px;
}

@media (max-width: 1069px) {
    .jw-contact {
        padding: 30px 0;
    }
    .jw-contact-info, .jw-contact-info-text, .contact-form {
        margin: 0 auto;
    }
    .jw-contact-info-text {
        text-align: center;
        padding: 20px 20px 40px 20px;
    }
    .contact-form {
        position: static;
        width: 90%;
        transform: translate(0,0);
        margin-top: -20px;
        height: auto;
    }
    .contact-form input, .contact-form textarea  {
        width: 100%;
    }
}


</style>
