<template>
    <div class="jw-office" id="o-kancelarii">
        <div class="tile">
            <div class="tile-content">
                <div class="jw-title">
                    {{ title }}
                </div>
                <div class="jw-text" v-html="content1"></div>
            </div>
        </div>
        <div class="tile gold"></div>
        <div class="tile blue"></div>
        <div class="tile">
            <div class="tile-content">
                <div class="jw-text" v-html="content2"></div>
            </div>

        </div>

    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "Office",
    computed: {
        title() {
            return this.aboutOfficeSections ? this.aboutOfficeSections[0].title : ''
        },
        content1() {
            return this.aboutOfficeSections ? this.aboutOfficeSections[0].content.html.replaceAll(/\n/g, '<br><br>') : ''
        },
        content2() {
            return this.aboutOfficeSections ? this.aboutOfficeSections[0].content2.html.replaceAll(/\n/g, '<br><br>') : ''
        }
    },
    apollo: {
        aboutOfficeSections: gql`
        query {
            aboutOfficeSections {
                title,
                content {
                    html
                },
                content2 {
                    html
                }
            }
        }
        `
    },
};
</script>

<style scoped>
.jw-office {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
}
.jw-tiles {
    width: 50%;
}
.tile {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.tile-content {
    padding: 40px;
}
.tile.gold {
    background-color: #C6B29D;
}
.tile.blue {
    background-color: #002D55;
}
.jw-title {
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .jw-office {
        flex-direction: column;
        padding: 30px 0;
    }
    .tile.gold, .tile.blue {
        display: none;
    }
    .tile {
        width: 100%;
    }
    .tile-content {
        padding: 0;
    }
    .jw-title {
        padding: 0 20px 0 20px;
    }
    .jw-text {
        padding: 0 20px 10px 20px;
    }

}
</style>
