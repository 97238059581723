<template>
    <div class="jw-notarial-activities" id="czynnosci-notarialne">
        <div class="container">
            <div class="jw-title">
                {{ title }}
            </div>
            <div class="jw-text" v-html="content"></div>
        </div>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "NotarialActivities",
    computed: {
        title() {
            return this.notarialActivitiesSections  ? this.notarialActivitiesSections [0].title : ''
        },
        content() {
            return this.notarialActivitiesSections  ? this.notarialActivitiesSections [0].content.html.replaceAll(/\n/g, '<br><br>') : ''
        }
    },
    apollo: {
        notarialActivitiesSections : gql`
        query {
            notarialActivitiesSections  {
                title,
                content {
                    html
                }
            }
        }
        `
    },
};
</script>

<style scoped>
.jw-notarial-activities {
    padding: 60px 0;
}
.jw-title {
    margin-bottom: 30px;
}
@media (max-width: 767px)  {
    .jw-notarial-activities {
        padding: 30px 0;
    }
}
</style>
