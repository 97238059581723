<template>
    <div class="jw-footer">
        <div class="container">
            <div class="jw-text">
                Kancelaria Notarialna Notariusz Jagoda Wiśniewska
            </div>
            <div class="jw-text">
                Projekt i realizacja strony <a href="https://maderity.com/" target="_blank">Maderity</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
};
</script>

<style scoped>
.jw-footer {
    background-color: #002D55;
    position: relative;
}
.container {
    display: flex;
    justify-content: space-between;
}
.jw-text {
    color: #fff;
    font-size: 12px;
    line-height: 40px;
}
.jw-text a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

@media (max-width: 767px) {
    .container {
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
    }

}
</style>
