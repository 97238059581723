import Vue from 'vue'
import App from './App.vue'
import ApolloClient from 'apollo-boost'
import VueApollo from 'vue-apollo'
import VueResource from 'vue-resource'

const apolloClient = new ApolloClient({
    uri: process.env.VUE_APP_GRAPH_CMS_URI || 'https://api-eu-central-1.graphcms.com/v2/ckky9owmg9yoi01z11erzdyr1/master'
});

Vue.use(VueApollo);
Vue.use(VueResource);
Vue.config.productionTip = false;

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

new Vue({
    apolloProvider,
    render: h => h(App)
}).$mount('#app')
